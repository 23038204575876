import { useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TabsMU from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import WallPostPage from '../WallPostPage'
import SelectCity from '../SelectCity'
import getAppId from '../../utils/getAppId'
import getCityTitle from '../../utils/getCityTitle'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.primary,
  },
}))

function Tabs() {
  const classes = useStyles()
  const [appId, setAppId] = useState(getAppId())
  const isFromGroup = useMemo(() => getCityTitle(appId), [appId])

  const handleChange = (event, newValue) => {
    if (!newValue) {
      setAppId(false)
    }
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <TabsMU value={isFromGroup ? 1 : 0} indicatorColor="primary" onChange={handleChange}>
          {isFromGroup && (
            <Tab icon={<ChevronLeftIcon />} style={{ minWidth: '30px', marginLeft: '30px' }} />
          )}
          <Tab label="Новое объявление" />
        </TabsMU>
      </AppBar>

      {!isFromGroup && (
        <Typography component="div" style={{ padding: 8 * 3, textAlign: 'center' }}>
          <SelectCity changeCity={setAppId} />
        </Typography>
      )}
      <Fade in={isFromGroup}>
        <Typography component="div" style={{ padding: 8 * 3 }}>
          {isFromGroup && <WallPostPage appId={appId} />}
        </Typography>
      </Fade>
    </div>
  )
}

export default Tabs
