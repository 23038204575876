import { useState, useEffect, useCallback } from 'react'
import { Field, FieldArray, useFormikContext } from 'formik'
import { makeStyles } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import { usePrevious } from '../../hooks'

import departments from '../../db.json'
import selectFieldStyles from './styles'

const useStyles = makeStyles(selectFieldStyles)

function SelectFields({ title, type, departs = departments, layer = 0, ...props }) {
  const classes = useStyles()
  const [depart, setDepart] = useState('')
  const { values, setValues } = useFormikContext()
  const prevDepart = usePrevious(values.departs[layer])

  const onChangeDepart = (e) => {
    setDepart(e.target.value)
    const formDepartsValues = [...values.departs]
    formDepartsValues[layer] = e.target.value
    setValues({ ...values, departs: formDepartsValues })
  }

  useEffect(() => {
    if (prevDepart && !values.departs[layer]) setDepart('')
  }, [values.departs])

  useEffect(() => {
    const newDeps = values.departs
    setValues({ ...values, departs: newDeps.filter((_, i) => i < layer) })
    setDepart('')
  }, [type])

  const renderSelectField = useCallback(
    ({ field, label, form: { touched, errors }, children, ...custom }) => (
      <TextField
        select
        required
        label={label}
        placeholder={label}
        onChange={custom.onChange}
        error={touched[field.name] && errors[field.name]}
        helperText={touched[field.name] && errors[field.name]}
        {...field}
        {...custom}
      >
        {children}
      </TextField>
    ),
    []
  )

  const renderTopLayer = () => (
    <>
      <div style={{ width: '100%' }}>
        <Field
          require
          id={`outlined-helperDepart${layer}`}
          variant="outlined"
          label={title || (layer === 0 ? 'Тип объявления' : layer === 1 ? 'Раздел' : null)}
          name={`departs.${layer}`}
          className={classes.formControl}
          component={renderSelectField}
          value={depart}
          onChange={onChangeDepart}
          key={`depart${layer}-${type}`}
        >
          {departs.map((depart) => (
            <MenuItem value={depart.id} key={depart.id}>
              {depart.print}
            </MenuItem>
          ))}
        </Field>
      </div>

      {renderChildren(departs, layer + 1, type)}
    </>
  )

  const renderChildren = (departs, layer, type) => {
    if (depart === undefined) return null
    const department = departs.find((item) => item.id === depart)
    if (!department || !department.children) return null

    if (department.multiple) {
      return renderMultiple(department, layer)
    }

    return (
      <SelectFields
        departs={department.children}
        title={department.print}
        layer={layer}
        type={type}
      />
    )
  }

  const renderMultiple = (departs, layer) => {
    return departs.children.map((depart, index) => {
      return <SelectFields departs={depart.children} title={depart.print} layer={layer + index} />
    })
  }

  if (!layer && !departments) return null

  return <FieldArray name="departs">{renderTopLayer()}</FieldArray>
}

export default SelectFields
