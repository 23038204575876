const API_BASE_URL = 'https://mvk.fatpeople.ru/api/posts'
const API_UPLOAD_URL = 'https://mvk.fatpeople.ru/api/upload'
const wallPost = async (data) => {
  const response = await fetch(API_BASE_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  return await response.json()
}

const uploadImgs2Server = async (data) => {
  const response = await fetch(API_UPLOAD_URL, {
    method: 'POST',
    body: data,
  })
  return await response.json()
}

export { wallPost, uploadImgs2Server }
