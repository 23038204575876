import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/es/DialogContentText/DialogContentText'
import Grid from '@material-ui/core/Grid'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Button from '@material-ui/core/Button'

import { GETTING_ERROR, POSTED } from '../../WallPostPage/constants'

export default function ResultDialog({ dialogOpen, status, onClose }) {
  return (
    <Dialog open={dialogOpen} onClose={onClose}>
      <DialogTitle>Оповещение</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={2}>
            {status === POSTED && <CheckCircleIcon fontSize="large" color="primary" />}
            {status === GETTING_ERROR && <WarningIcon fontSize="large" color="secondary" />}
          </Grid>
          <Grid item xs={10}>
            <DialogContentText>
              {status === POSTED && 'Ваше сообщение успешно опубликовано'}
              {status === GETTING_ERROR && 'Возникла непредвиденная ошибка при публикации'}
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="primary">
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}
