export const PENDING = 'PENDING'
export const CONFIRMATION = 'CONFIRMATION'
export const POSTING = 'POSTING'
export const POSTED = 'POSTED'
export const ADD_IMAGE = 'ADD_IMAGE'
export const REMOVE_IMAGE = 'REMOVE_IMAGE'
export const CLEAR_IMAGES = 'CLEAR_IMAGES'
// maybe when i'll come to making images
//export const SHOWING_RESULT = 'SHOWING_RESULT'
export const IMAGES_NOT_YET_UPLOADED = 'IMAGES_NOT_YET_UPLOADED'
export const IMAGES_UPLOADING = 'IMAGES_UPLOADING'
export const IMAGES_UPLOADED = 'IMAGES_UPLOADED'

export const CHANGE_POST_STATUS = 'CHANGE_POST_STATUS'
export const CHANGE_IMAGES_STATUS = 'CHANGE_IMAGES_STATUS'

export const GETTING_ERROR = 'GETTING_ERROR'
