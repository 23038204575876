import places from '../places.json'

const getCityTitle = (appId) => {
  if (false === appId) return false
  for (let place of places) {
    if (+place?.app_id === +appId) {
      return place.title
    }
    if (place.cities) {
      for (let city of place?.cities) {
        if (+city?.app_id === +appId) {
          return city.title
        }
      }
    }
  }
  return false
}

export default getCityTitle
