import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Formik, Form, Field } from 'formik'
import { useState } from 'react'

import wallStyles from './styles'
import useWallPostPageLogic from './hooks'

import ResultDialog from '../common/dialogs/ResultDialog'
import PromptDialog from '../common/dialogs/PromptDialog'
import TextField from '../common/Fields/TextField'
import SelectFields from '../SelectFields'
import UploadPhoto from './UploadPhoto'
import { createContext } from 'react'
import SelectPlace from '../SelectPlace'
import CheckboxField from '../common/Fields/CheckboxField'
import getUserId from '../../utils/getUserId'
import getCityTitle from '../../utils/getCityTitle'

const useStyles = makeStyles(wallStyles)
export const WallPostContext = createContext({})

function WallPostPage({ appId }) {
  const cityTitle = getCityTitle(appId)
  const classes = useStyles()
  const initialValues = {
    title: '',
    descr: '',
    price: '',
    tags: '',
    departs: [],
    town: '',
    viewer_id: getUserId(),
    app_id: appId,
    images: [],
  }
  const { guards, dispatches, onSubmit, state } = useWallPostPageLogic()

  const { resultDialogOnOpen, promptDialogOnOpen, isUploaded, isPending } = guards
  const { resultDialogOnClose, promptDialogOnClose, promptDialogOnConfirm } = dispatches
  const [showTowns, setShowTowns] = useState(false)

  const onShowHandler = () => {
    setShowTowns(!showTowns)
  }

  return (
    <WallPostContext.Provider value={{ guards, dispatches, state }}>
      <div className={classes.container}>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ setFieldValue }) => (
            <Form className={classes.mainForm}>
              <div>
                <Field
                  required
                  id="outlined-helperTitle"
                  label="Название"
                  className={classes.textField}
                  // helperText="От 3 до 45 символов"
                  margin="normal"
                  variant="outlined"
                  name="title"
                  component={TextField}
                />
              </div>
              <div>
                <Field
                  required
                  id="outlined-multiline-static"
                  label="Описание"
                  multiline
                  rows="4"
                  className={classes.textField}
                  helperText="рекомендуем до 1145 символов"
                  margin="normal"
                  variant="outlined"
                  name="descr"
                  component={TextField}
                />
              </div>
              <div>
                <Field
                  id="outlined-helperPrise"
                  label="Цена"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  name="price"
                  helperText="только цифры"
                  inputProps={{
                    pattern: '^([0-9]{1,3}.? ?([0-9]{3}.? ?)*[0-9]{3}|[0-9]+)(,[0-9][0-9])?$',
                  }}
                  component={TextField}
                />
              </div>

              <SelectFields />

              <div>
                <span className={classes.checkboxField}>
                  Будет опубликовано в г. {cityTitle}, или
                </span>
              </div>

              <div>
                <Field
                  id="outlined-helperAreneedplace"
                  label="Уточнить место"
                  className={classes.checkboxField}
                  margin="normal"
                  name="areneedplace"
                  component={CheckboxField}
                  checked={showTowns}
                  onShow={onShowHandler}
                />
              </div>

              {showTowns && <SelectPlace appId={appId} />}

              <div>
                <Field
                  id="outlined-helperTags"
                  label="Хештеги"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  name="tags"
                  component={TextField}
                />
              </div>

              <div style={{ marginBottom: '-30px' }}>
                <UploadPhoto updateImagesHandler={setFieldValue} />
              </div>

              <input type="hidden" name="viewer_id" />
              <input type="hidden" name="images" />

              <div className={classes.wrapper}>
                <Typography variant="caption">* разделы обязательные к заполнению</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                  disabled={!isPending && !isUploaded}
                >
                  Отправить
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <ResultDialog
          dialogOpen={resultDialogOnOpen}
          onClose={resultDialogOnClose}
          status={state.post}
        />
        <PromptDialog
          dialogOpen={promptDialogOnOpen}
          onClose={promptDialogOnClose}
          onConfirm={promptDialogOnConfirm}
        />
      </div>
    </WallPostContext.Provider>
  )
}

export default WallPostPage
