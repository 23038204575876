const UploadPhotoButton = (props) => {
  return (
    <button
      className="upload-photo-button"
      onClick={(e) => e.preventDefault()}
      disabled={props.disabled}
    />
  )
}

export default UploadPhotoButton
