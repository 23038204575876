import React from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

function CheckboxField(props) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          name="checkedI"
          color="primary"
          checked={props.checked}
          onChange={props.onShow}
        />
      }
      {...props}
    />
  )
}

export default CheckboxField
