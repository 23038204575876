import { useReducer, useRef, useState } from 'react'
import {
  guardCanImagesBeUploaded,
  guardDoShowConfirmation,
  guardImagesUploaded,
  guardImagesUploading,
  guardIsReady2Confirm,
  guardIsReady2ShowResult,
  guardIsPending,
  guardIsPosting,
} from './guards'
import {
  go2Pending,
  go2Uploading,
  go2Uploaded,
  gotError,
  havePosted,
  ready2Confirmation,
  startPosting,
  setImages,
  clearImages,
  removeImage,
} from './ac'
import { PENDING, POSTING } from './constants'
import { wallPost } from '../../api/wall'
import wallPostReducer from './reducer'

export default function useWallPostPageLogic() {
  const [state, dispatch] = useReducer(wallPostReducer, {
    post: PENDING,
    images: [],
  })
  const postStatus = useRef(state.post)

  const onSubmit = async (values, actions) => {
    if (guardIsReady2Confirm(state)) {
      dispatch(ready2Confirmation())
      await new Promise((resolve) => {
        let tick = () => {
          if (postStatus.current === POSTING) {
            clearTimeout(timerId)
            resolve()
          } else {
            timerId = setTimeout(tick, 200)
          }
        }
        let timerId = setTimeout(tick, 200)
      })
      try {
        console.log('Sent values: ', values)
        const result = await wallPost(values)
        actions.resetForm()
        dispatch(havePosted())
      } catch (err) {
        console.log(err)
        dispatch(gotError())
      }
    }
  }
  return {
    guards: {
      areImagesUploading: guardImagesUploading(state),
      resultDialogOnOpen: guardIsReady2ShowResult(state),
      promptDialogOnOpen: guardDoShowConfirmation(state),
      doShowUploadButton: guardCanImagesBeUploaded(state),
      isUploaded: guardImagesUploaded(state),
      isPending: guardIsPending(state),
      isPosting: guardIsPosting(state),
    },
    dispatches: {
      resultDialogOnClose: () => {
        postStatus.current = PENDING
        dispatch(go2Pending())
      },
      promptDialogOnClose: () => dispatch(go2Pending()),
      promptDialogOnConfirm: () => {
        dispatch(startPosting())
        postStatus.current = POSTING
      },
      imagesHaveBeenDeleted: () => dispatch(go2Pending()),
      imagesStartUploading: () => dispatch(go2Uploading()),
      imagesHaveBeenUploaded: () => dispatch(go2Uploaded()),
      setImages: (file, image) => dispatch(setImages(file, image)),
      clearImages: () => dispatch(clearImages()),
      removeImage: (i) => dispatch(removeImage(i)),
    },
    onSubmit,
    state,
  }
}
