import { memo, useCallback, useEffect, useState } from 'react'
import { Field, useFormikContext } from 'formik'
import { makeStyles } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import { usePrevious } from '../../hooks'
import places from '../../places.json'
import selectPlacesStyles from './styles'
const useStyles = makeStyles(selectPlacesStyles)

function SelectPlace({ appId }) {
  const classes = useStyles()
  const { values, setValues } = useFormikContext()
  const [townes, setTownes] = useState([])
  const [town, setTown] = useState('')
  const prevTown = usePrevious(values.town)

  useEffect(async () => {
    outer: for (let place of places) {
      if (place.app_id === appId) {
        setTownes(place.townes)
        break
      }

      if (!place.cities) continue

      for (let city of place.cities) {
        if (city.app_id === appId) {
          setTownes(place.townes)
          break outer
        }
      }
    }
  }, [appId])

  useEffect(() => {
    if (prevTown && !values.town) setTown('')
  }, [values.town])

  const renderTownes = useCallback(
    (townes) => {
      if (!townes.length) return null

      return (
        <>
          <div style={{ width: '100%' }}>
            <Field
              id={'outlined-place-2'}
              variant="outlined"
              label="Населенный пункт"
              name="town"
              className={classes.formControl}
              component={renderSelectTown}
              value={town}
              onChange={(e) => {
                setTown(e.target.value)
                setValues({ ...values, town: e.target.value })
              }}
            >
              {townes.map((town, index) => (
                <MenuItem value={town} key={index}>
                  {town}
                </MenuItem>
              ))}
            </Field>
          </div>
        </>
      )
    },
    [appId, town, values]
  )

  const renderSelectTown = memo(
    ({ field, label, form: { touched, errors }, children, ...custom }) => (
      <TextField
        select
        required={custom.require}
        label={label}
        placeholder={label}
        onChange={custom.onChange}
        error={touched[field.name] && errors[field.name]}
        helperText={touched[field.name] && errors[field.name]}
        {...field}
        {...custom}
      >
        {children}
      </TextField>
    )
  )

  return <>{renderTownes(townes)}</>
}

export default SelectPlace
