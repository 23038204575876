import { useRef, useEffect } from 'react'

const Photo = ({ thumb, onRemove }) => {
  const ref = useRef(null)

  useEffect(() => {
    ref.current.appendChild(thumb)
  }, [])

  return (
    <div className="photo">
      <div ref={ref} />
      <div className="delete" onClick={onRemove} />
    </div>
  )
}

export default Photo
