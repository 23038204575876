import {
  CHANGE_IMAGES_STATUS,
  CHANGE_POST_STATUS,
  ADD_IMAGE,
  IMAGES_NOT_YET_UPLOADED,
  REMOVE_IMAGE,
  CLEAR_IMAGES,
} from './constants'

function postStatusReducer(state, action) {
  switch (action.type) {
    case CHANGE_POST_STATUS:
      return action.payload.status
    case ADD_IMAGE: {
      return IMAGES_NOT_YET_UPLOADED
    }
    case REMOVE_IMAGE:
    case CLEAR_IMAGES:
    default:
      return state
  }
}

function imagesStatusReducer(state, action) {
  switch (action.type) {
    case ADD_IMAGE: {
      return [...state, action.payload.image]
    }
    case REMOVE_IMAGE: {
      return [...state.filter((_, i) => action.payload.id !== i)]
    }
    case CLEAR_IMAGES: {
      return []
    }
    default:
      return state
  }
}

export default function wallPostReducer(state, action) {
  return {
    post: postStatusReducer(state.post, action),
    images: imagesStatusReducer(state.images, action),
  }
}
