import {
  CHANGE_POST_STATUS,
  CONFIRMATION,
  PENDING,
  POSTED,
  POSTING,
  GETTING_ERROR,
  ADD_IMAGE,
  CLEAR_IMAGES,
  REMOVE_IMAGE,
  IMAGES_UPLOADING,
  IMAGES_UPLOADED,
} from './constants'

export function ready2Confirmation() {
  return { type: CHANGE_POST_STATUS, payload: { status: CONFIRMATION } }
}

// export function ready2ShowResult() {
//   return { type: CHANGE_POST_STATUS, payload: SHOWING_RESULT }
// }

export function go2Pending() {
  return { type: CHANGE_POST_STATUS, payload: { status: PENDING } }
}

export function startPosting() {
  return { type: CHANGE_POST_STATUS, payload: { status: POSTING } }
}

export function havePosted() {
  return { type: CHANGE_POST_STATUS, payload: { status: POSTED } }
}

export function gotError() {
  return { type: CHANGE_POST_STATUS, payload: { status: GETTING_ERROR } }
}

export function go2Uploading() {
  return { type: CHANGE_POST_STATUS, payload: { status: IMAGES_UPLOADING } }
}

export function go2Uploaded() {
  return { type: CHANGE_POST_STATUS, payload: { status: IMAGES_UPLOADED } }
}

export function setImages(file, image) {
  return { type: ADD_IMAGE, payload: { image: { file, image } } }
}

export function clearImages() {
  return { type: CLEAR_IMAGES }
}

export function removeImage(i) {
  return { type: REMOVE_IMAGE, payload: { id: i } }
}
