import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/es/DialogContentText/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'

export default function PromptDialog({ dialogOpen, onClose, onConfirm }) {
  return (
    <Dialog open={dialogOpen} onClose={onClose}>
      <DialogTitle>Подтвердите действие</DialogTitle>
      <DialogContent>
        <DialogContentText>Вы действительно хотите отправить это объявление?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Отмена
        </Button>
        <Button variant="contained" onClick={onConfirm} color="primary">
          Отправить
        </Button>
      </DialogActions>
    </Dialog>
  )
}
