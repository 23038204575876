import { green } from '@material-ui/core/colors'

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mainForm: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0,
    display: 'block',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  checkboxField: {
    color: 'rgba(0, 0, 0, 0.54)',
    paddingLeft: '9px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

export default styles
