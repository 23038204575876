import { useEffect, useState, useContext } from 'react'
import { WallPostContext } from '../index'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ImageSearch from '@material-ui/icons/ImageSearch'
import VideoLibrary from '@material-ui/icons/VideoLibrary'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import UploadPhotoButton from './UploadPhotoButton'
import Photo from './Photo'
import { IMAGES_NOT_YET_UPLOADED } from '../constants'
import 'fileapi/dist/FileAPI'
import './UploadPhoto.css'
import prepareFormData2Upload from '../../../utils/prepareFormData2Upload'
import { uploadImgs2Server } from '../../../api/wall'
import { Field, useFormikContext } from 'formik'
import { usePrevious } from '../../../hooks'
import TextField from '../../common/Fields/TextField'

const UploadPhoto = ({ updateImagesHandler }) => {
  const MAX_SIZE = 10
  const ERROR_MAX_SIZE = `Максимально только ${MAX_SIZE} фото. Будут загружены первые ${MAX_SIZE}`
  const { guards, dispatches, state } = useContext(WallPostContext)
  const { values, setValues } = useFormikContext()
  const prevImages = usePrevious(values.images)
  const prevTitle = usePrevious(values.title)
  const { doShowUploadButton, isUploaded, areImagesUploading, isPosting } = guards
  const {
    imagesHaveBeenDeleted,
    imagesStartUploading,
    imagesHaveBeenUploaded,
    setImages,
    clearImages,
    removeImage,
  } = dispatches
  const imagesLength = state.images.length
  const [anchorEl, setAnchorEl] = useState(null)
  const [errors, setErrors] = useState({})
  const [thumbs, setThumbs] = useState([])
  const [showUrlImageField, setShowUrlImageField] = useState(false)

  useEffect(() => {
    guardMaxPhotos()
    const imgs = state.images.map((el) => el.image)
    setThumbs(imgs)
  }, [imagesLength])

  useEffect(() => {
    if (prevImages?.length && !values.images.length) {
      setThumbs([])
      clearImages()
    }
  }, [values.images])

  useEffect(() => {
    if (prevTitle && !values.title) {
      setShowUrlImageField(false)
    }
  }, [values.title])

  useEffect(() => {
    window.FileAPI.event.on(window.choose, 'change', function (evt) {
      const files = window.FileAPI.getFiles(evt) // Retrieve file list
      let fileInfo = null
      window.FileAPI.filterFiles(
        files,
        function (file, info) {
          if (/^image/.test(file.type)) {
            fileInfo = info
            return file.size <= 30000000
          }
          return false
        },
        function (files, rejected) {
          if (files.length) {
            // Создаем предпросмотр с шириной 200 и пропорциональной высотой
            new Promise((resolve, reject) => {
              let newThumbs = []
              files.map((file, i) => {
                return window.FileAPI.Image(file)
                  .preview(200, Math.round((200 * fileInfo.height) / fileInfo.width))
                  .get((err, img) => {
                    img.num = Math.random()
                    newThumbs.push(img)
                    if (newThumbs.length === files.length) {
                      resolve(newThumbs)
                    }
                  })
              })
            }).then((newThumbs) => {
              setImages(files[0], newThumbs[0])
            })
          }
        }
      )
    })
  }, [])

  useEffect(() => {
    if (state.images.length === 0 && state.post === IMAGES_NOT_YET_UPLOADED) {
      window.choose.querySelector('.upload-input-el').value = null
      imagesHaveBeenDeleted()
    }
  })

  const uploadImages = async () => {
    imagesStartUploading()
    const images = await uploadImgs2Server(
      prepareFormData2Upload(state.images.map((el) => el.file))
    )
    imagesHaveBeenUploaded()
    updateImagesHandler('images', images)
  }

  const toggleImageUrlField = () => {
    handleClose()
    setShowUrlImageField(!showUrlImageField)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderPhotos = () => {
    if (!thumbs.length) return null
    return (
      <div className="photos">
        {thumbs.map((thumb, i) => (
          <Photo key={thumb.num} thumb={thumb} onRemove={() => removeImage(i)} />
        ))}
        <div style={{ color: 'red' }}>{errors.max_error_size}</div>
      </div>
    )
  }

  const guardMaxPhotos = () => {
    if (state.images && state.images.length >= MAX_SIZE) {
      setErrors({
        ...errors,
        max_error_size: ERROR_MAX_SIZE,
      })
    } else {
      setErrors({})
    }
  }

  return (
    <>
      <div className="uploadContainer">
        <div className="js-fileapi-wrapper upload-btn" id="choose">
          <UploadPhotoButton disabled={isUploaded} />
          <input name="files" type="file" className="upload-input-el" disabled={isUploaded} />
        </div>
        <div className="adv-menu">
          <Button
            aria-controls="vk-simple-menu"
            aria-haspopup="true"
            onClick={(e) => {
              setAnchorEl(e.currentTarget)
            }}
          >
            <MoreHorizIcon color="primary" />
          </Button>
          <Menu
            id="vk-simple-menu"
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={toggleImageUrlField}>
              <ImageSearch color="primary" /> Url картинки
            </MenuItem>
            {/*<MenuItem onClick={handleClose}>*/}
            {/*  <VideoLibrary color="primary" /> Url видео*/}
            {/*</MenuItem>*/}
          </Menu>
        </div>
        {showUrlImageField && (
          <div style={{ marginBottom: '50px' }}>
            <Field
              id="outlined-helperImgUrl"
              label="Адрес страницы изображения в vk.com"
              className="textField"
              margin="normal"
              variant="outlined"
              name="img_url"
              helperText="Полный адрес картинки из vk, пример https://(m.)vk.com/id1?z=photo1_456264771%2Falbum1_0%2Frev"
              component={TextField}
            />
          </div>
        )}
        {doShowUploadButton && (
          <div className="upload-text-container" style={{ marginTop: '-20px' }}>
            <span className="notif">
              <span className="warn">Внимание!</span> Чтобы опубликовать объявление, нужно загрузить
              картинки на сервер{' '}
            </span>
            <span className="upload-text">
              <Button
                variant="contained"
                color="default"
                onClick={(e) => {
                  e.stopPropagation()
                  uploadImages()
                }}
                startIcon={<CloudUploadIcon />}
                style={{ margin: '10px 0' }}
                disabled={isUploaded}
              >
                Загрузить
              </Button>
            </span>
          </div>
        )}

        {isUploaded && (
          <div className="upload-text-container">
            <span className="upload-text">
              <span>Теперь можно опубликовать пост</span>
            </span>
          </div>
        )}
        {areImagesUploading && <CircularProgress size={20} />}

        <div className="results">{renderPhotos()}</div>
      </div>

      <Backdrop className="cover" open={isPosting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default UploadPhoto
