import {
  CONFIRMATION,
  GETTING_ERROR,
  IMAGES_NOT_YET_UPLOADED,
  IMAGES_UPLOADED,
  IMAGES_UPLOADING,
  PENDING,
  POSTED,
  POSTING,
} from './constants'

export function guardIsReady2Confirm(state) {
  return state.post === PENDING || state.post === IMAGES_UPLOADED
}

export function guardIsReady2ShowResult(state) {
  return state.post === POSTED || state.post === GETTING_ERROR
}

// export function guardDoShowResult(state) {
//   return state.post === SHOWING_RESULT
// }

export function guardDoShowConfirmation(state) {
  return state.post === CONFIRMATION
}

export function guardIsPending(state) {
  return state.post === PENDING
}

export function guardCanImagesBeUploaded(state) {
  switch (state.post) {
    case IMAGES_NOT_YET_UPLOADED:
    case IMAGES_UPLOADING:
    case IMAGES_UPLOADED:
      return true
    default:
      return false
  }
}

export function guardIsPosting(state) {
  return state.post === POSTING
}

export function guardImagesUploaded(state) {
  return state.post === IMAGES_UPLOADED
}

export function guardImagesUploading(state) {
  return state.post === IMAGES_UPLOADING
}
