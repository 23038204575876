import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import getAppIdFromCities from '../../utils/getAppIdFromCities'

import places from '../../places.json'
import selectPlacesStyles from './styles'
const useStyles = makeStyles(selectPlacesStyles)

function SelectCity({ changeCity }) {
  const classes = useStyles()
  const [start, setStart] = useState(false)
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [showNextBtn, setShowNextBtn] = useState(false)
  const place = places.find((place) => place.id === state)

  useEffect(() => {
    if (getAppIdFromCities(state, city)) {
      setShowNextBtn(true)
    } else {
      setShowNextBtn(false)
    }
  }, [state, city])

  const renderStates = () => (
    <>
      <div style={{ width: '100%', textAlign: 'left' }}>
        <TextField
          select
          id={'outlined-place-0'}
          label="Регион"
          variant="outlined"
          placeholder="Регион"
          onChange={(e) => setState(e.target.value)}
          value={state}
          className={classes.formControl}
        >
          {places.map((state) => (
            <MenuItem value={state.id} key={state.id}>
              {state.title}
            </MenuItem>
          ))}
        </TextField>
      </div>

      {renderCities(place?.cities)}
    </>
  )

  const renderCities = (cities) => {
    if (!state || !cities) return null

    return (
      <>
        <div style={{ width: '100%', textAlign: 'left' }}>
          <TextField
            select
            id={'outlined-place-1'}
            variant="outlined"
            label="Город"
            placeholder="Город"
            className={classes.formControl}
            onChange={(e) => setCity(e.target.value)}
            value={city}
          >
            {cities.map((city) => (
              <MenuItem value={city.id} key={city.id}>
                {city.title}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </>
    )
  }

  return (
    <>
      {!start && (
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
          onClick={() => setStart(true)}
        >
          <AddIcon /> Новое объявление
        </Fab>
      )}
      {start && renderStates()}
      {showNextBtn && (
        <Fab
          variant="extended"
          size="small"
          color="primary"
          aria-label="add"
          onClick={() => changeCity(getAppIdFromCities(state, city))}
        >
          Поехали
        </Fab>
      )}
    </>
  )
}

export default SelectCity
