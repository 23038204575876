import places from '../places.json'

const getAppIdFromCities = (stateId, cityId = null) => {
  for (let place of places) {
    if (+place?.id === +stateId) {
      if (!cityId && !('cities' in place)) return place.app_id
      if (!cityId) return false
      for (let city of place?.cities) {
        if (+city?.id === +cityId) {
          return city.app_id
        }
      }
    }
  }
  return false
}

export default getAppIdFromCities
